import {config} from './config';
import { dataLayer } from "./datalayer";
import {gup, trimProtocol, isPageAgain} from "./helpers";
import {GAEvent} from "./ga";
import * as ut from "./ut";

export function personas(){
    if (config.isGDPR){
        return;
    }

    function isMatch(mode) { //this can be loosey
        let host = trimProtocol(config.docReferrer).split('/')[0];

        for (let prop in personas) {
            if (matched) {
                break;
            }

            for (let i = 0; i < personas[prop].length; i++) {
                if (personas[prop][i].indexOf('/') > -1) {
                    if (config.docReferrer.indexOf(personas[prop][i]) > -1) {
                        persona = prop;
                        matched = true;
                        break;
                    }
                } else if ((mode === 'full' && host === personas[prop][i]) || (mode === 'sub' && host.indexOf(personas[prop][i]) > -1)) {
                    persona = prop;
                    matched = true;
                    break;
                }
            }
        }
    }

    let com = '.com',
        politics = '/politics',
        personas = {
            // Todd: ['fastcompany' + com, 'gizmodo' + com, 'techcrunch' + com, 'mashable' + com, 'cleantechnica' + com, 'wired' + com, 'venturebeat' + com, 'recode.net', 'arstechnica' + com, 'cnet' + com],
            // Ken: ['bloomberg' + com, 'forbes' + com, 'money.cnn' + com, 'marketwatch' + com, 'economist' + com, 'environmentalleader' + com, 'wsj' + com, 'fortune' + com, 'ft' + com, 'greenbiz' + com, 'businessinsider' + com, 'triplepundit' + com],
            Maureen: ['politico' + com, 'thehill' + com, 'realclearpolitics' + com, 'washingtonpost' + com + politics, 'foxnews' + com + politics, 'npr.org/sections' + politics, 'c-span.org', 'nationaljournal' + com, 'cqrollcall' + com, 'huffingtonpost' + com + '/section' + politics, 'cnn' + com + politics, 'nytimes' + com + '/section' + politics, 'msnbc' + com, 'msnbc' + com + '/republicans', 'msnbc' + com + '/democrats', 'nbcnews' + com + politics]
        },
        personaIDs = {
            '1': 'Annie',
            '8': 'Ken',
            '14': 'Todd',
            '16': 'Maureen',
            '19': 'KTL',
            '20': 'HVP',
            '21': 'Keith'
        },
        persona = false,
        personaID = false,
        aud = gup('aud'),
        cta = gup('ub_cta'),
        target = gup('ub_tg'),
        matched = false;

    let visibility_cta = ['13', '8', '14'], //video views, shares, reach
        engagement_cta = ['4', '10', '3'], //clicks, landing page views, opens
        decision_cta = ['2', '6', '11', '12', '1', '7', '9']; //actions, donations, lead generation, newsletter signups, recruits, renewals, sustainers

    if (personaIDs[aud]) {
        persona = personaIDs[aud];
        personaID = aud;

        //special treatment for HVP and Keith targets
        if ((aud === '20' || aud === '21') && cta) {
            if (visibility_cta.indexOf(cta) > -1) {
                persona += ' - Visibility';
            }
            else if (engagement_cta.indexOf(cta) > -1) {
                persona += ' - Engagement';
            }
            else if (decision_cta.indexOf(cta) > -1) {
                persona += ' - Decision';
            }
        }
    }
    else if (config.docReferrer) {
        isMatch('full');
        isMatch('sub');
    }

    if (persona && !isPageAgain()) {
        //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#join_group

        let group_id = 'P_'+personaID;

        if (target){
            group_id += '-T_'+target;
        }

        if (cta){
            group_id += '-C_'+cta;
        }

        GAEvent('join_group', {group_id: group_id});

        ut.setSess('persona_id', personaID);
        ut.setSess('persona_group', group_id);
    }

    dataLayer[0].persona = persona.toString();
    dataLayer[0].personaID = personaID.toString();
    dataLayer[0].personaLabel = persona.toString();
}