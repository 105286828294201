import {config} from "./config";
import {$qs, forAll} from "./dom";
import { push } from "./datalayer";
import {decorateForm, describeStarterFormGAItem, GAEvent} from "./ga";
import {addEvent} from "./events";
import {encodeFormData, formDataToJSON} from "./forms";
import {gup, removeEPs, isEmail, trimObject} from "./helpers";
import {isBot} from "./client_detection";
import {sendMessage} from "./classy";
import {on_mailchimp_signup} from "./utilities";
import * as lytics from "./lytics";

export function onSubmit(){
    let preventDefault = true;

    function listenForSubmit(e){
        if (isBot){
            return false;
        }

        let form = e.target;

        if (form){
            let data = formDataToJSON(form),
                form_className = form.className || '',
                form_name = form.getAttribute('name'),
                form_action = form.getAttribute('action') || '';

            if (preventDefault){
                GAEvent('submit', {
                    ec: 'forms',
                    at: form.getAttribute('method') || 'GET',
                    af: form_action || config.PN,
                    el: data.addl_info || form.dataset.name || form_name || form_className || null,
                    ei: 1
                });
            }

            if (form_className.indexOf('mc4wp-form') > -1 
                || form_name === 'mc-embedded-subscribe-form' 
                || form_action.indexOf('.list-manage.com/subscribe/post') > -1
            ){                
                let email = $qs('input[type="email"]', form) || $qs('input[name="email"]', form) || $qs('input[name="EMAIL"]', form),
                    id = form.dataset.id || '',
                    name = form.dataset.name || form_name || '',
                    had_email = email && email.value && isEmail(email.value);

                if (form_action && gup('id', form_action)){
                    id = gup('id', form_action);
                }

                return on_mailchimp_signup({
                    email: email,
                    list_id: id,
                    at: 'wp_form',
                    af: id + ': ' + name,
                    el: had_email ? 'had_email' : 'lacked_email',
                    had_email: had_email,
                    name: name,
                    form: form
                });
            }

            if (config.isInsider && form_className.indexOf('comment-form') > -1){
                GAEvent('comment', {
                    ut: true,
                    ec: 'insider',
                    at: 'comment_form',
                    af: form_action
                });
            }

            if (config.isAdestra && (document.title+'').toLowerCase().indexOf('unsubscribe') > -1){
                let form_id = null;

                for (let prop in data){
                    if (prop.toLowerCase().indexOf('form_id') > -1){
                        form_id = data[prop];
                    }
                }

                GAEvent('unsubscribe', {
                    ut: true,
                    ec: 'adestra',
                    af: form_id
                });
            }

            if (config.isWoobox && $EDF.getWooboxData){
                let {form_data, results, session, send_to_lytics, shard} = $EDF.getWooboxData(form);

                if (['x5uamv'].indexOf(shard) > -1 || send_to_lytics){
                    let lytics_obj = {};
                    for (let prop in form_data){
                        if (prop.indexOf('formdata_'+shard) !== 0){
                            lytics_obj[prop] = form_data[prop];
                        }
                    }

                    lytics.event(trimObject(lytics_obj));
                }

                GAEvent('woobox_submit', {
                    ec: 'woobox',
                    af: shard,
                    at: type,
                    el: results.value || null
                });

                push({
                    ut: true,
                    pivot_to_event_for_ut: false,
                    event_source: 'woobox',
                    event: 'woobox_submit',
                    woobox_formdata: form_data,
                    woobox_results: results.value || null,
                    woobox_session_input: session.value || null,
                    woobox_context: window.context || null,
                    woobox_options: window.options || null
                });
            }

            if (preventDefault && (form_className.indexOf('starter-form') > -1 || form_className.indexOf('donation-form') > -1)){
                e.preventDefault();

                preventDefault = false;
                setTimeout(function(){
                    preventDefault = true;
                }, 250);

                let donation_link = form_action,
                    querystring = encodeFormData(form);

                if (querystring){
                    donation_link += '?' + querystring;
                }

                let inputs_to_remove = ['source', 'sub_source'];
                for (let i = 0; i < config.custom_strings.length; i++) {
                    inputs_to_remove.push('custom_string' + config.custom_strings[i]);
                }

                GAEvent('donation_link_click', {
                    at: 'click',
                    af: removeEPs(donation_link, null, inputs_to_remove),
                    ei: 1
                });

                GAEvent('select_promotion', {ei: 1, items: describeStarterFormGAItem(form)});

                if ((config.PN === '/' || form_className.indexOf('c-site-nav__btn_form') > -1) && config.hasClassyEmbedVariant){
                    sendMessage(formDataToJSON(form))

                    $qs('header .donate-and-search .button.mobile').click();

                    return false;
                }

                decorateForm(form);

                setTimeout(function(){
                    form.submit();
                }, 50);
            }
        }
    }

    function checkForForms(){
        forAll('form', function(form){
            let attached_class = 'form-subscribed-to';

            if (!form.classList.contains(attached_class)){
                if (config.isDev && (form.action+'').indexOf('/apps/mailchimp') > -1){
                    setTimeout(function(){
                        form.action = form.action.replace('stage.', 'test.');
                    }, 5000);
                }

                addEvent(form, 'submit', listenForSubmit);
                form.classList.add(attached_class)
            }
        });
    }

    if (!config.is_classy_studio){
        checkForForms();

        setInterval(checkForForms, 1000);
    }
}