import { config } from "./config";
import {isLinkLink} from "./functions";
import {dec, getExtension, getParentDomain, gup, isDocument, isDownload, qrySep, removeEPs, stripParam, trim, wait} from "./helpers";
import {addReadyEvent, addEvent} from './events';
import {decorateGA4Link, GAEvent, virtualPage} from "./ga";
import * as adestra from "./adestra_auth";
import {edfActionClicks, classyClicks} from "./site_hooks";
import {isMacSafari, isBot} from "./client_detection";
import * as ut from "./ut";
import {getDomPath} from "./dom";

export function clicker(){
    addReadyEvent(function() {
        if (!config.isUtility){
            let links = document.getElementsByTagName('a');

            for (let i = 0; i < links.length; i++) {
                let el = links[i],
                    original_url = el.href,
                    new_url = original_url,
                    gift = null,
                    redirects = config.php_gift_redirects;

                if (isLinkLink(original_url)) {
                    for (let j = 0; j < redirects.length; j++) {
                        if (original_url.indexOf(redirects[j]) > -1) {
                            gift = redirects[j];
                        }
                    }

                    if (gift) {
                        if (isBot){
                            new_url = 'https://donate.edf.org/give/505786/#!/donation/checkout';
                        }
                        else if (new_url.indexOf(gift) === new_url.length - gift.length) {
                            new_url += '/';
                        }
                        else if (new_url.indexOf(gift + '?') > -1) {
                            new_url = new_url.replace(gift + '?', gift + '/?');
                        }
                    }
                    else if (new_url.indexOf('/site/Donation2') > -1 && gup('df_id', new_url)) {
                        let tmp = '&' + gup('df_id', new_url) + '_don';
                        if (new_url.indexOf(tmp) > -1) {
                            new_url = new_url.replace(tmp, tmp.replace('_', '.'));
                        }
                    }
                    else if (new_url.indexOf('/site/Advocacy') > -1 && new_url.indexOf('pagename=homepage') > -1) {
                        new_url = new_url.replace('pagename=homepage', 'cmd=display&page=UserAction');
                    }

                    if (new_url.indexOf('https://nam11.safelinks.protection.outlook.com') === 0){
                        let url = gup('url', new_url);
                        if (url){
                            let decoded = dec(url);
                            if (decoded !== url){
                                new_url = decoded;
                            }
                        }
                    }

                    if (new_url.indexOf('://dv8.edf.org') > -1){
                        new_url = new_url.replace('://dv8.edf.org', '://dev.edf.org');
                    }

                    if (gup('rd', new_url)){
                        new_url = stripParam('rd', new_url);
                    }

                    let a = document.createElement('a'),
                        donate_path = '/donate/';

                    a.href = new_url;

                    //converts links like www.edf.org/donate/feature-card-example to www.edf.org/donate/?addl_info=feature-card-example
                    if (['dv8.edf.org', 'dev.edf.org', 'www.edf.org', 'stage.edf.org'].indexOf(a.hostname) > -1 && a.pathname !== donate_path && a.pathname.indexOf(donate_path) === 0){
                        let path_level = a.pathname.replace(donate_path, '').replace(/\//g, '_');
                        new_url = 'https://'+a.hostname+donate_path+a.search;

                        if (new_url.indexOf('addl_info=') === -1){
                            new_url = new_url+qrySep(new_url)+'addl_info='+path_level;
                        }
                    }
                    else if (a.pathname.indexOf('/donate%3F') === 0){
                        new_url = decodeURIComponent(new_url).replace('/donate?', donate_path+'?');
                    }

                    if (isMacSafari && el.getAttribute('target') === '_blank'){
                        el.removeAttribute('target');
                    }

                    for (let i = 0; i < config.link_params.length; i++) {
                        new_url = stripParam(config.link_params[i], new_url);
                    }

                    if (new_url.indexOf('&') > -1 && new_url.indexOf('?') === -1){
                        new_url = new_url.replace('&', '?');
                    }

                    if (original_url !== new_url){
                        el.href = new_url;
                    }
                }
            }
        }

        addEvent(document, 'click', function(e) {
            let is_classy_embed_trigger = false;

            if (config.isClassy){
                classyClicks(e);
            }

            if (config.isImpact2021 && typeof ($EDF.impact2021 || {}).clicks === 'function'){
                $EDF.impact2021.clicks(e);
            }

            if ((config.isImpact2022 || config.isVision2030) && typeof ($EDF.impact2022_and_vision2030 || {}).clicks === 'function'){
                $EDF.impact2022_and_vision2030.clicks(e);
            }

            if (config.isImpact2023 && typeof ($EDF.impact2023 || {}).clicks === 'function'){
                $EDF.impact2023.clicks(e);
            }

            if (config.isMethaneSat && typeof ($EDF.methanesat || {}).clicks === 'function'){
                $EDF.methanesat.clicks(e);
            }

            if (config.isMM && typeof ($EDF.methanemoment || {}).clicks === 'function'){
                $EDF.methanemoment.clicks(e);
            }

            if (config.isGCA && typeof ($EDF.gca || {}).clicks === 'function'){
                $EDF.gca.clicks(e);
            }

            if (config.main_site && typeof ($EDF.main_site || {}).clicks === 'function'){
                $EDF.main_site.clicks(e);
            }

            if (config.edaf_main_site){
                edfActionClicks(e);
            }

            if (config.isMV && typeof ($EDF.methane_villain || {}).clicks === 'function'){
                $EDF.methane_villain.clicks(e);
            }

            if (config.isVS && typeof ($EDF.vital_signs || {}).clicks === 'function'){
                $EDF.vital_signs.clicks(e);
            }

            if (config.isNZA && typeof ($EDF.netzeroaction.clicks || {}) === 'function'){
                $EDF.netzeroaction.clicks(e);
            }

            if (config.isInsider && typeof ($EDF.insider.clicks || {}) === 'function'){
                $EDF.insider.clicks(e);
            }

            //want to let classy embed triggers through to generate donation_link_click events....but no force the redirect
            if (e.target && e.target.classList && e.target.classList.contains('classy_embed_trigger')){
                e.preventDefault();
                is_classy_embed_trigger = true;
            }
            else {
                if (e.defaultPrevented){
                    return;
                }

                if (config.main_site && window.blockLinkClicks) {
                    return e.preventDefault();
                }

                if ((config.isTesting || config.isDev) && gup('defaultPrevented') === 'TRUE'){
                    return e.preventDefault();
                }
            }

            let el = e.target,
                parent = el.parentElement || el.parentNode,
                that,
                i;

            if (parent) {
                parent = Array.isArray(parent) ? parent : [parent];
            }

            if (el.tagName === 'A') {
                that = el;
            }
            else if (parent) {
                for (i = 0; i < parent.length; i++) {
                    if (parent[i].tagName === 'A') {
                        that = parent[i];
                        break;
                    }
                }
            }

            if (!that){
                that = el.closest('a');
            }

            if (that && that.classList && that.classList.contains('classy_embed_trigger')){
                is_classy_embed_trigger = true;
            }

            if (is_classy_embed_trigger){
                e.preventDefault();
            }

            let click = {
                tag: (that || el).tagName,
                target: (that || el).getAttribute('target') || null,
                href: (that || el).getAttribute('href') || null,
                text: (that || el).innerText || null,
                selector: getDomPath(that || el).join(' '),
                x: e.clientX,
                y: e.clientY
            }

            for (let prop in click){
                if (typeof click[prop] === 'string'){
                    click[prop] = click[prop].trim();

                    if (prop === 'text' && click.text.length > 99){
                        click.text = click.text.slice(0,96)+'...';
                    }
                }
            }

            config.clicks.push(click);

            if (config.clicks.length > 50){
                config.clicks = config.clicks.slice(1);
            }

            if (that && !!that.getAttribute('href')) {
                let link = trim(that.getAttribute('href')),
                    text = that.innerText,
                    absolute = false,
                    isOneOfUs = 0,
                    isSameDomain = 0,
                    target_attr = that.getAttribute('target') || null,
                    rel = that.getAttribute('rel') || null,
                    do_not_handle = that.getAttribute('data-dnh');

                if (do_not_handle) {
                    return;
                }

                if (link.indexOf('javascript:void') === 0 && !!that.dataset.original_url){ //TODO could improve with isLinkLink
                    link = that.dataset.original_url;
                }

                if (isMacSafari && that.getAttribute('target') === '_blank'){
                    that.removeAttribute('target');
                }

                config.last_click = {
                    l: removeEPs(link),
                    t: text
                };

                if (config.isDev && gup('cookieconsent') === 'true'){
                    that.href = link + qrySep(link) + 'cookieconsent=true';
                }

                if (isLinkLink(link)) {
                    if (link.indexOf('mailto') === 0) { //WARN: this triggers the unload event in some browsers
                        let mailto_link = link.substring(7);

                        if (mailto_link.indexOf('@') > -1){
                            mailto_link = mailto_link.split('@');
                            mailto_link[0] = mailto_link[0][0]+mailto_link[0][1]+'...'+mailto_link[0][mailto_link[0].length-1];
                            mailto_link = mailto_link.join('@');
                        }

                        GAEvent('click', {
                            af: mailto_link,
                            at: 'mailto_link_click',
                            ei: 1
                        })
                    }
                    else {
                        if (link.indexOf('http') === 0 || link.indexOf('//') === 0) {
                            absolute = true;
                        }

                        if (link.indexOf('http') === -1) {
                            link = config.LN + '//' + config.HN + (link.indexOf('/') !== 0 ? '/' : '') + link;
                        }

                        let a = document.createElement('a');
                        a.href = link;

                        let pathname = a.pathname,
                            hostname = a.hostname;

                        for (i = 0; i < config.us.length; i++) {
                            if (hostname === config.us[i] || getParentDomain(hostname) === getParentDomain(config.us[i])) {
                                isOneOfUs = 1;

                                if (getParentDomain(hostname) === config.HNUpper) {
                                    isSameDomain = 1;
                                }
                            }
                        }

                        if (!isOneOfUs && hostname === config.HN) {
                            isOneOfUs = true;
                            isSameDomain = true;
                        }

                        if (isOneOfUs && !isSameDomain) {
                            link = decorateGA4Link(link)
                        }

                        if (isOneOfUs) {
                            if (isDownload(link)) {
                                e.preventDefault();

                                let check = typeof text !== 'undefined' &&
                                    text !== '',
                                    ext = getExtension(link).toUpperCase();

                                if (isDocument(link)) {
                                    that.setAttribute('target', '');
                                    virtualPage(pathname, check ? text : ext + ' document');
                                }
                                else {
                                    GAEvent({ec: 'File Click', ea: link, el: check ? text : ext + ' file', ei: 1});
                                }

                                wait(link, 100, target_attr, rel);
                            }
                            else if (pathname === '/site/Donation2'
                                || pathname.indexOf('/donate/') === 0 //accounts for Drupal content nodes
                                || config.php_gift_redirects.indexOf(pathname[pathname.length-1] === '/' ? pathname.slice(0, pathname.length-1) : pathname) > -1
                                || (config.classy_hosts.indexOf(hostname) > -1 && hostname !== config.HN)
                            ) {
                                e.preventDefault();

                                if (config.php_gift_redirects.indexOf(pathname) > -1){
                                    link = link.replace(pathname, pathname+'/');
                                }

                                if (adestra.auth() && link.indexOf('ping=session') === -1) {
                                    link += qrySep(link) + 'ping=session';
                                }

                                if (ut.getSess('utm_id')){
                                    link += qrySep(link) + 'c_src='+ut.getSess('utm_id');
                                }

                                that.href = link;

                                let donation_link = pathname === '/site/Donation2' && gup('df_id', link)
                                    ? gup('df_id', link) : (pathname.indexOf('/give/') === 0 ? pathname.replace('/give/', '').replace('/', '') : pathname);

                                GAEvent('donation_link_click', {at: 'click', af: donation_link, ei: 1}); //not doing roll-up `click` event so that it can be called out as conversion without creating duplicative events. though adding in ei:1 after the fact somewhat defeats this purpose

                                if (is_classy_embed_trigger) {
                                    return false;
                                }

                                wait(link, 100, target_attr, rel);
                            }
                            else if (pathname === '/site/Advocacy'
                                || ((config.P2A_hosts.indexOf(hostname) > -1 || config.EA_hosts.indexOf(hostname) > -1)
                                        && hostname !== config.HN)
                            ) {
                                e.preventDefault();

                                if (adestra.auth() && link.indexOf('ping=session') === -1) {
                                    link += qrySep(link) + 'ping=session';
                                }

                                let utm_id = ut.getSess('utm_id');
                                if (utm_id){
                                    link += qrySep(link) + 'oa_utm_id='+utm_id+'&ms='+utm_id;
                                }

                                that.href = link;

                                let action_link = pathname === '/site/Advocacy' && gup('id', link) ? gup('id', link) : pathname.replace('/', '');

                                GAEvent('action_link_click', {at: 'click', af: action_link, ei: 1}); //not doing roll-up `click` event so that it can be called out as conversion without creating duplicative events. though adding in ei:1 after the fact somewhat defeats this purpose

                                wait(link, 100, target_attr, rel);
                            }
                            else if (config.isUtility && target_attr === '_blank'){
                                GAEvent('click', {
                                    at: 'admin_link_click',
                                    af: link
                                });
                            }
                            else if (pathname.indexOf('/expert/email') === 0){ //https://dv8.edf.org/expert/email/5492
                                GAEvent('expert_email_click', {at: 'click', af: pathname, ei: 1});
                            }
                            else if (pathname.indexOf('/contact/email') === 0){ //https://dv8.edf.org/contact/email/585/related/5492
                                GAEvent('contact_email_click', {at: 'click', af: pathname, ei: 1});
                            }
                        }
                        else if (absolute) {
                            e.preventDefault();

                            if (is_classy_embed_trigger){
                                return false;
                            }

                            GAEvent('click', {at: 'outbound', af: link});
                            wait(link, 100, target_attr, rel);
                        }
                    }
                }
                else if (is_classy_embed_trigger){
                    return false;
                }
            }
        });
    });
}