//https://www.fillout.com/help/custom-code

import {constants} from './constants';
import {gup, $id, $name, gti, removeEPs, getParentDomain, getConnectionData, getUrlParams, isEmail, getClassyStudioProps} from './helpers';

let config = {
    ...getConnectionData(),
    online: true
};

let GAPROD = constants.GAPROD,
    GADEV = constants.GADEV,
    GIFT_PLANS = 'edf.giftplans.org',
    BPI_TYPEFORM = 'v9up3rn33br.typeform.com',
    PANTHEON_IP = '.pantheonsite.io',
    IMPACT2022_DEV = 'dev-edf-impact-report'+PANTHEON_IP,
    IMPACT2023_DEV = 'dev-edf-impact-report-2023'+PANTHEON_IP,
    VITAL_SIGNS = 'vitalsigns.edf.org',
    WOOBOX = 'woobox.com',
    DEV_MAIN_SITE_PLATFORM_SH = 'www.dev-54ta5gq-knre2npbximey.us-2.platformsh.site',
    PROD_MAIN_SITE_PLATFORM_SH = 'www.main-bvxea6i-knre2npbximey.us-2.platformsh.site',
    document_title_lower = (document.title+'').toLowerCase(),
    IS_PANTHEON_MULTISITE = location.hostname.indexOf('edf-blogs'+PANTHEON_IP) > -1;

let edf_redirect = 'https://www.edf.org/donate/';
let classy_accounts =  {
    '63864': {
        alias: 'edf',
        name: 'Environmental Defense Fund',
        host: 'donate.edf.org',
        redirect: edf_redirect
    },
    '72343': {
        alias: 'edfaction',
        name: 'EDF Action',
        host: 'donate.edfaction.org',
        redirect: 'https://www.edfaction.org/donate/'
    },
    '72349': {
        alias: 'edfactionpac',
        name: 'EDF Action PAC',
        host: 'donate.pac.edfaction.org',
        redirect: 'https://www.edfaction.org/donate-pac/'
    },
    '72344': {
        alias: 'mcaf',
        name: 'Moms Clean Air Force',
        host: 'donate.momscleanairforce.org',
        redirect: 'https://www.momscleanairforce.org/donate/'
    },
    '72346': {
        alias: 'cama',
        name: 'Clean Air Moms Action',
        host: 'donate.cleanairmomsaction.org',
        redirect: 'https://cleanairmomsaction.org/donate/'
    },
    '72345': {
        alias: 'dof',
        name: 'Defend Our Future',
        host: 'donate.defendourfuture.org',
        redirect: 'https://defendourfuture.org/donate/'
    },
    // '72347': {
    //     alias: 'dofaction',
    //     host: 'donate.defendourfutureaction.org',
    //     redirect: 'https://defendourfutureaction.org/donate/'
    // },
    '72347': {
        alias: 'edfactiontxpac',
        name: 'Environmental Defense Action Fund Texas PAC',
        host: 'donate.txpac.edfaction.org',
        redirect: 'https://www.edfaction.org/donate-txpac/'
    },
    '71388': {
        alias: 'edf',
        name: 'Environmental Defense Fund - SANDBOX',
        host: 'donate.sandbox.edf.org',
        redirect: edf_redirect
    }
};

let obj = {
    GAOBJ: {
        'www.edf.org': GAPROD,
        'dev.edf.org': GADEV,
        'dv8.edf.org': GADEV,
        'stage.edf.org': GADEV,
        'seafood.edf.org': GAPROD,
        'dev.seafood.edf.org': GADEV,
        'fisherysolutionscenter.edf.org': GAPROD,
        'dev.fisherysolutionscenter.edf.org': GADEV,
        ['dev-edf-fishery'+PANTHEON_IP]: GADEV,
        ['live-edf-fishery'+PANTHEON_IP]: GADEV,
        ['test-edf-fishery'+PANTHEON_IP]: GADEV,
        'blogs.edf.org': GAPROD,
        ['dev-edf-blogs'+PANTHEON_IP]: GADEV,
        ['test-edf-blogs'+PANTHEON_IP]: GADEV,
        ['live-edf-blogs'+PANTHEON_IP]: GADEV,
        'wpdev.edf.org': GADEV,
        'business.edf.org': GAPROD,
        'www.edfaction.org': GAPROD,
        'dv8.edfaction.org': GADEV,
        'fishe.edf.org': GAPROD,
        'dev.fishe.edf.org': GADEV,
        'dv8.fishe.edf.org': GADEV,
        'mexdev.edf.org': GADEV,
        'mexico.edf.org': GAPROD,
        'jpndev.edf.org': GADEV,
        'japan.edf.org': GAPROD,
        'cl-perudev.edf.org': GADEV,
        'chile-peru.edf.org': GAPROD,
        'dev50th.edf.org': GADEV,
        '50years.edf.org': GAPROD,
        'supplychain.edf.org': GAPROD,
        //'plannedgiving.edf.org': GAPROD,
        //'edfgift.org': GAPROD,
        //'stelterstaging.ingeniuxondemand.com': GADEV,
        [GIFT_PLANS]: GAPROD, //WARNING: site maintains a static snapshot of utilities asset
        'act.edf.org': GAPROD,
        'act.edfaction.org': GAPROD,
        'actnow.edf.org': GAPROD,
        'actnow.edfaction.org': GAPROD,
        'email.edf.org': GAPROD,
        'email.edfaction.org': GAPROD,
        'donate.edf.org': GAPROD,
        'donate.edfaction.org': GAPROD,
        'donate.pac.edfaction.org': GAPROD,
        'donate.txpac.edfaction.org': GAPROD,
        'donate.sandbox.edf.org': GADEV,
        'edfclimate.wpengine.com': GADEV,
        'climatesolutions.edf.org': GAPROD,
        'greenmycart.web.app': GADEV,
        'sustainabuy.com': GAPROD,
        'www.sustainabuy.com': GAPROD,
        'impact2020.edf.org': GAPROD,
        'impact2021.edf.org': GAPROD,
        'impact2021edf.kinsta.cloud': GADEV,
        'impact2022.edf.org': GAPROD,
        [IMPACT2022_DEV]: GADEV,
        'impact2023.edf.org': GAPROD,
        [IMPACT2023_DEV]: GADEV,
        [constants.UTD]: '86795986-1',
        ['stage.'+constants.UTD]: '86795986-2',
        'vision2030.edf.org': GAPROD,
        'edfvision2030.kinsta.cloud': GADEV,
        'electricfleet.org': GAPROD,
        'www.electricfleet.org': GAPROD,
        'careers-edf.icims.com': GAPROD,
        'innovationtracker.edf.org': GAPROD,
        'betterhubs.edf.org': GAPROD,
        [BPI_TYPEFORM]: GAPROD,
        [WOOBOX]: GAPROD,

        ['dev-vital-signs-edf'+PANTHEON_IP]: GADEV,
        ['test-vital-signs-edf'+PANTHEON_IP]: GADEV,
        ['dev-'+VITAL_SIGNS]: GADEV,
        ['stage-'+VITAL_SIGNS]: GADEV,
        [VITAL_SIGNS]: GAPROD,
        'vital-signs.docksal.site': GADEV,
        'netzeroaction.org': GAPROD,
        ['dev-edf-nza'+PANTHEON_IP]: GADEV,
        ['test-edf-nza'+PANTHEON_IP]: GADEV,
        'fundingtoaction.edf.org': GAPROD,
        ['dev-edf-climate-innovation-case-studies'+PANTHEON_IP]: GADEV,
        ['test-edf-climate-innovation-case-studies'+PANTHEON_IP]: GADEV,
        [DEV_MAIN_SITE_PLATFORM_SH]: GADEV,
        [PROD_MAIN_SITE_PLATFORM_SH]: GADEV,
        'chemicalactionmap.edf.org': GAPROD,
        ['dev-tsca-action-map'+PANTHEON_IP]: GADEV,

        '50years.ddev.site': GADEV,
        'chile-peru.ddev.site': GADEV,
        'edf.ddev.site': GADEV,
        'edfaction.ddev.site': GADEV,
        'fishe.ddev.site': GADEV,
        'japan.ddev.site': GADEV,
        'mexico.ddev.site': GADEV,
        'indonesia.ddev.site': GADEV,

        ['dev-edf-action-2023'+PANTHEON_IP]: GADEV,
        ['test-edf-action-2023'+PANTHEON_IP]: GADEV,
        ['live-edf-action-2023'+PANTHEON_IP]: GADEV
    },
    QS: removeEPs(location.search, constants.adestra_params),
    HNUpper: getParentDomain(constants.HN),
    current_page: location.href,
    pageStartTime: performance && performance.timing && performance.timing.domLoading ? performance.timing.domLoading : gti(),
    pageEndTime: null,
    errorStack: [],
    src: '',
    subsrc: '',
    docHost: '',
    docPath: '',
    user_id: null,
    _HPC: window._HPC || '',
    custom_values: window.custom_values || [],
    convio_user: window.convio_user || {}, //TODO remove after re-doing userFlags
    getUser: {},
    last_click: null,
    clicks: [],
    dl_cleared: false,
    drupalUID: window.drupalUID || window.drupal_uid || window.uid
};

for (let prop in constants){
    obj[prop] = constants[prop];
}

obj.isInsider = ['insider.edf.org', 'insider.ddev.site', 'insider-edforg.msappproxy.net', 'main-bvxea6i-xyo4ircpzg25i.us-3.platformsh.site'].indexOf(obj.HN) > -1;

if ((obj.HN.indexOf('.edf.org') > -1 || obj.HN.indexOf('.edfaction.org') > -1) && !obj.GAOBJ[obj.HN]){
    if (!obj.isInsider){
        obj.GAOBJ[obj.HN] = obj.HN.indexOf('dev') > -1 ? GADEV : GAPROD;
    }
}

obj.isConvio = obj.HN === obj.convioDomain;
obj.isLocal = window.isLocal !== false && ((obj.HN.indexOf('localhost') === 0 || obj.HN === '127.0.0.1') || obj.HN === obj.lndo || obj.HN.indexOf('.ddev.site') > -1);
obj.isTugboat = obj.HN.indexOf('tugboat.qa') > -1 || obj.HN.indexOf('tugboatqa.com') > -1;
obj.main_site = (obj.isLocal && !!window.main_site) || ['dv8.edf.org', 'dev.edf.org', 'www.edf.org', 'stage.edf.org', 'edf.ddev.site', DEV_MAIN_SITE_PLATFORM_SH, PROD_MAIN_SITE_PLATFORM_SH].indexOf(obj.HN) > -1 || obj.HN === obj.lndo || obj.isTugboat;
obj.edaf_main_site = ['dv8.edfaction.org', 'www.edfaction.org', 'edfaction.ddev.site'].indexOf(obj.HN) > -1 || obj.HN.indexOf('edf-action-2023') > -1;
obj.isEDAF = obj.HN.indexOf('edfaction') > -1 || BPI_TYPEFORM === obj.HN || obj.HN.indexOf('edf-action-2023') > -1;
obj.isTesting = window.isTesting || gup('chums') === 'testing' || gup('isTesting') === 'true' || gup('pagename').indexOf('_dev') > -1;
obj.isP2A = obj.P2A_hosts.indexOf(obj.HN) > -1;
obj.isEA = obj.EA_hosts.indexOf(obj.HN) > -1;
obj.isAdestra = obj.adestra_hosts.indexOf(obj.HN) > -1;
obj.isClassy = obj.classy_hosts.indexOf(obj.HN) > -1 || (obj.isLocal && obj.PN.indexOf('/give/') === 0);
obj.isClassyEmbed = obj.isClassy && (gup('eg') === 'true' || gup('egp') === 'do' || gup('egrn') === 'true' || gup('egfa') === 'true');
obj.isInClassyEmbed = obj.isClassyEmbed && window.location !== window.parent.location;
obj.is_classy_studio = window.is_classy_studio || new RegExp('^(\/campaign|\/checkout)').test(obj.PN);
obj.classy_studio = window.classy_studio_props || getClassyStudioProps() || null;

for (let account in classy_accounts){
    if (obj.HN === classy_accounts[account].host){
        obj.classy_brand = classy_accounts[account].alias;
        obj.classy_org = classy_accounts[account].name;
        obj.classy_org_id = parseInt(account);
    }
}

obj.isWoobox = obj.HN === WOOBOX; //CAUTION: assuming isWoobox is just for EDF brand for now
obj.isMemTool = obj.isAdestra || obj.isClassy || obj.isEA || obj.isWoobox;
obj.isFSC = obj.HN.indexOf('fisherysolutionscenter.edf.org') > -1 || obj.HN.indexOf('fishery'+PANTHEON_IP) > -1;
obj.isCVI_DEV = obj.HN === 'demo.edf.dha.io';
obj.isCVI = obj.HN.indexOf('climatevulnerabilityindex.org') > -1 || obj.isCVI_DEV;
obj.isTSCA = ['chemicalactionmap.edf.org', 'dev-tsca-action-map'+PANTHEON_IP].indexOf(obj.HN) > -1;
obj.preClassyCutover = new Date() < new Date('2021-05-27');

if (obj.isEA && window.isDev && document_title_lower.indexOf('dev') === -1 && document_title_lower.indexOf('test') === -1){
    window.isDev = false;
}

obj.isEDF = (obj.main_site || obj.isEDAF || obj.HN.indexOf('.edf.org') > -1 || obj.isWoobox) && !obj.isInsider;
obj.isDOF = window.isDOF || (obj.HN.indexOf('defendourfuture.org') > -1 || (obj.isDev && obj.PN.indexOf('/defendourfuture') === 0));
obj.isDOFC4 = window.isDOFC4 || (obj.HN.indexOf('defendourfutureaction') > -1 || (obj.isDev && obj.PN.indexOf('/dofaction') === 0));
obj.isMCAFC4 = window.isMCAFC4 || obj.HN.indexOf('cleanairmomsaction') > -1 || (obj.isDev && obj.PN.indexOf('/mcafc4') === 0);
obj.isMCAFC3 = window.isMCAF || obj.HN.indexOf('momsclean') > -1
obj.isMCAF = obj.isMCAFC3 || obj.isMCAFC4;
obj.isMCAFMain = obj.HN === 'www.momscleanairforce.org';
obj.isMRD = window.isMRD || (obj.HN.indexOf('mississippiriverdelta.org') > -1 || (obj.isDev && obj.PN.indexOf('/mrdnew') === 0));
obj.isC4 = obj.isEDAF || obj.isDOFC4 || obj.isMCAFC4;
obj.is50th = ['50years.edf.org', 'dev50th.edf.org', '50years.ddev.site'].indexOf(obj.HN) > -1;
obj.isBiz = obj.HN === 'business.edf.org' || (obj.HN === 'wpdev.edf.org' && obj.PN.indexOf('/business') === 0) || obj.HN.indexOf('edf-business'+PANTHEON_IP) > -1;
obj.isEDFCS = obj.HN === 'edfclimate.wpengine.com' || obj.HN === 'climatesolutions.edf.org';
obj.isImpact2020 = obj.HN === 'impact2020.edf.org' || (obj.isLocal && window.local_domain_proxy === 'impact2020.edf.org') || (IS_PANTHEON_MULTISITE && obj.PN === '/impact2020');
obj.isImpact2021 = obj.HN.indexOf('impact2021') > -1 || (obj.isLocal && window.local_domain_proxy === 'impact2021.edf.org') || (IS_PANTHEON_MULTISITE && obj.PN === '/impact2021');
obj.isImpact2022 = obj.HN.indexOf('impact2022') > -1 || obj.HN === IMPACT2022_DEV || (IS_PANTHEON_MULTISITE && obj.PN === '/impact2022');
obj.isImpact2023 = obj.HN.indexOf('impact2023') > -1 || obj.HN.indexOf('impact-report-2023'+PANTHEON_IP) > -1 || (IS_PANTHEON_MULTISITE && obj.PN === '/impact2023');
obj.isVision2030 = obj.HN.indexOf('vision2030') > -1;
obj.isVS = obj.HN.indexOf('vital') > -1 && obj.HN.indexOf('signs') > -1;
obj.isTFCI = obj.HN.indexOf('tfciguide') > -1 || (obj.PN.indexOf('/tfciguide') === 0 && obj.HN.indexOf('.edf.org') > -1);
obj.isFTA = obj.HN === 'fundingtoaction.edf.org' || obj.HN.indexOf('edf-climate-innovation-case-studies') > -1;
obj.isMultisiteButNotBlogs = IS_PANTHEON_MULTISITE && (obj.isImpact2020 || obj.isImpact2021 || obj.isImpact2022 || obj.isImpact2023 || obj.isVision2030 || obj.isBiz || obj.is50th || obj.isVS);
obj.isBlogs = !obj.isMultisiteButNotBlogs
    && ((['blogs.edf.org', 'wpdev.edf.org'].indexOf(obj.HN) > -1 && !obj.isMCAF && !obj.isMCAFC4 && !obj.isMRD && !obj.isDOF && !obj.isDOFC4 && !obj.isBiz && !obj.isTFCI) || IS_PANTHEON_MULTISITE);
obj.isWebguide = obj.HN.indexOf('webguide') > -1;
obj.isMexico = obj.HN.indexOf('mexico') === 0 || obj.HN.indexOf('mexdev') === 0;
obj.isJapan = obj.HN.indexOf('japan') === 0 || obj.HN.indexOf('jpndev') === 0;
obj.isChilePeru = obj.HN.indexOf('peru') > -1;
obj.isGCA = obj.HN.indexOf('globalcleanair.org') > -1 || obj.PN.indexOf('/globalcleanair') === 0;
obj.isGCAMap = obj.PN.indexOf('/air-tracker/map') === 0;
obj.isMM = obj.HN.indexOf('methanemoment.org') > -1;
obj.isSSFH = obj.HN.indexOf('ssfhub.org') > -1;
obj.isEU = obj.HN.indexOf('edfeurope.org') > -1 || obj.HN === 'europe.ddev.site';
obj.isCMEU = obj.HN.indexOf('cutmethaneeu.org') > -1;
obj.isCMCA = obj.HN.indexOf('cutmethane.ca') > -1 || obj.HN.indexOf('cutmethaneca') > -1;
obj.isCC = obj.HN.indexOf('clearcollab.org') > -1;
obj.isEET = obj.HN.indexOf('edfenergytransition.org') > -1 || obj.HN === 'edf-methanesat.webflow.io';
obj.isMethaneSat = obj.HN.indexOf('methanesat.org') > -1 || obj.isEET || obj.HN === 'main-bvxea6i-b522ybjlcjyh6.us-2.platformsh.site';
obj.isCBNS = obj.HN.indexOf('createnbs.org') > -1;

obj.isMV = obj.current_page.indexOf('https://northstreetcreative.com/edf-methane') === 0 || obj.current_page.indexOf('https://edf-org.github.io/methane-villain') === 0;

if (obj.isMV){
    obj.isEDF = true;
    obj.GAOBJ[obj.HN] = obj.HN === 'methane-villain.edf.org' ? GAPROD : GADEV; //TODO update with prod domain
}

obj.isElectricFleet = obj.HN.indexOf('electricfleet.org') > -1;
obj.isNZA = obj.HN.indexOf('netzeroaction') > -1 || obj.HN.indexOf('edf-nza') > -1;
obj.isCVE = obj.HN.indexOf('communityvoicesinenergy.org') > -1 || obj.HN.indexOf('community-voices-energy'+PANTHEON_IP) > -1;
obj.isSupplyChain = obj.HN.indexOf('supplychain') > -1;
obj.isFRI = obj.HN.indexOf('frontlineresourceinstitute.org') > -1 || (obj.HN === 'wpdev.edf.org' && obj.PN.indexOf('/fri') === 0);
obj.isMicroSite = obj.isMM || obj.isSSFH || obj.isMethaneSat || obj.isDOF || obj.isDOFC4 || obj.isMCAF || obj.isMRD;
obj.isWordpress = obj.isBiz || obj.isNZA || obj.isEDFCS || obj.isBlogs || obj.isImpact2020 || obj.isImpact2021 || obj.isImpact2022 || obj.isImpact2023 || obj.isVision2030 || obj.HN === 'wpdev.edf.org' || obj.isMCAF || obj.isMRD || obj.isDOF || obj.isMethaneSat || obj.isElectricFleet || obj.isCVE || obj.isNZA || obj.isCMCA;
obj.isDrupal  = obj.main_site || obj.isJapan || obj.isMexico || obj.isChilePeru || obj.HN === 'www.edfaction.org' || obj.HN === 'dv8.edfaction.org' || obj.isInsider || obj.isVS || obj.isFTA; //TODO: NOTE EDF Action main site switching from Drupal to Wordpress
obj.isUtility = obj.HN.indexOf(obj.UTD) > -1 && (obj.PN.indexOf('/apps') === 0 || obj.PN.indexOf('/admin') === 0);
obj.isSustainabuy = obj.HN === 'greenmycart.web.app' || obj.HN.indexOf('sustainabuy.com') > -1;
obj.isCBJ = obj.HN.indexOf('cleanbeautyjustice.org') > -1;
obj.isReact = obj.isSustainabuy;
obj.isGDPR = obj.isGCA || obj.isSSFH || obj.isCMEU || obj.isEU || obj.isNZA || obj.isMexico || obj.isJapan || obj.isChilePeru;
obj.isJobs = obj.HN.indexOf('icims') > -1;
obj.isBPITF = obj.HN === BPI_TYPEFORM;
obj.isGP = obj.HN === GIFT_PLANS;
obj.isAAAClimate = obj.HN.indexOf('aaaclimateleadership.org') > -1;

obj.HEADTAG = obj.isWordpress ? $id('dataAdmin') : $name('HEAD');
obj.isAdmin = window.isAdmin || obj.isAdmin || (obj.HEADTAG && obj.HEADTAG.getAttribute('data-admin') === 'true') || (obj.isWordpress && $id('dataAdmin') && $id('dataAdmin').getAttribute('content') === 'true');

obj.verbose = gup('verbose') === 'true';
obj.isDevHosting = obj.HN.indexOf(PANTHEON_IP) > -1 || obj.HN.indexOf('tugboat') > -1 || obj.HN.indexOf('platformsh.site') > -1;
obj.isDev = window.isDev
    || obj.HN === 'stage.'+obj.UTD
    || obj.HN === 'test.'+obj.UTD
    || obj.isLocal
    || obj.isTugboat
    || obj.GAOBJ[obj.HN] === obj.GADEV
    || obj.HN.indexOf('dev.') > -1
    || obj.HN.indexOf('beta.') > -1
    || obj.HN.indexOf('sandbox.') > -1
    || obj.HN.indexOf('test.') > -1
    || obj.HN.indexOf('testing.') > -1
    || (obj.isAdestra && obj.PN.indexOf('/dev_') > -1)
    || (obj.isClassy && obj.isTesting)
    || obj.HN.indexOf('webflow.io') > -1
    || obj.isDevHosting
    || obj.isCVI_DEV
    || obj.HN === 'beta.methanesat.org';

obj.isProd = !obj.isDev;

obj.useDev = obj.isDev || obj.isLocal || (obj.isMemTool && obj.isTesting);
obj.topDomain = obj.useDev ? (config.HN==='stage.edf.org'?'stage':'dev') : 'www';
obj.assets_path = 'https://' + (obj.useDev ? 'test.' : '') + 'assets.edfcdn.org/assets/';
obj.tracking_path = 'https://' + obj.topDomain + '.edf.org/js/assets/';
obj.utility_path = 'https://' + (obj.useDev ? 'test.' : '') + obj.UTD+'/apps/';

['edfaction.org', 'cleanairmomsaction.org', 'momscleanairforce.org', 'defendourfuture.org', 'defendourfutureaction.org'].forEach(function(domain){
    if (obj.HN.indexOf(domain) > -1){
        //provides brand-based endpoint for special cases to handle CORS. shouldn't be used by default as it would defeat purpose of setting edf_uuid
        obj.brand_utility_path = obj.UTD.replace('edf.org', domain);
    }
});

if (!obj.GAOBJ[obj.HN] && obj.isBlogs){ //handles cases like https://plugins-edf-blogs.pantheonsite.io/blogs-edf-org-climate411/
    obj.GAOBJ[obj.HN] = GADEV;
}

obj.runGA = ((!obj.isAdmin || obj.isDev) && obj.GAOBJ[obj.HN] && typeof window.stopGA === 'undefined' && !window._haltGAFromRunning) || obj.isTugboat;
obj.log = obj.isDev || obj.isTesting;

if (window.Intl && typeof Intl.DateTimeFormat === 'function' && typeof Intl.DateTimeFormat().resolvedOptions === 'function' && Intl.DateTimeFormat().resolvedOptions().locale){
    let locale = Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase();
    obj.isIntl = locale !== 'en-us' && locale.indexOf('-us') === -1;
    //Intl.DateTimeFormat().resolvedOptions().timeZone
}
else if (navigator.language && !obj.isGDPR) {
    obj.isIntl = navigator.language.toLowerCase().indexOf('-us') === -1;
}

let gup_cc = gup('cookieconsent') === 'true';
//https://docs.google.com/spreadsheets/d/1SlQLVtGspHfAIHghoR00ublVlPnukTeBarN_cZxf_7Q/edit#gid=0
//TODO make sure to configure privacy policy links and styles when adding to new sites
obj.useCookieConsent = !obj.isMemTool && !obj.isUtility && !obj.isInsider && !obj.isInClassyEmbed && (
    ((obj.useDev || obj.isTesting) && gup_cc)
    || obj.isEDF //includes EDF and EDF Action
    || obj.isDOF || obj.isDOFC4 || obj.isMCAFC4 || obj.isMRD
    || obj.isSustainabuy || obj.isElectricFleet
    || obj.isMethaneSat
);

if (obj.isEA && obj.isTesting && gup_cc){
    obj.useCookieConsent = true;
}

obj.javascript_blocked_for_consent = 'javascript/blocked_for_consent';

//Q5CTTQBJD8 prod edf and microsites
//2B3856Y9QW prod edf
//WE3BPRQKW0 test prod edf
//T5X6BFGPZ2 dev edf
//5TN38N31ZV dev edf and microsites

obj.ga4_linker_domains = constants.EDFdomains.concat(['methanesat.org', 'edfenergytransition.org', 'methanemoment.org', 'sustainabuy.com', 'defendourfuture.org', 'defendourfutureaction.org', 'momscleanairforce.org', 'cleanairmomsaction.org', 'mississippiriverdelta.org', BPI_TYPEFORM]);

obj.ga4_stream = [];
if (obj.isUtility){
    obj.ga4_stream.push(obj.isDev ? '39M9LJ37PT' : '63EHTVX4C0');
}
else if (obj.runGA) {
    if ([obj.GADEV, obj.GAPROD].indexOf(obj.GAOBJ[obj.HN]) > -1){
        if (obj.isProd){
            obj.ga4_stream.push('2B3856Y9QW');
        }
        // obj.ga4_stream.push(obj.isDev ? 'T5X6BFGPZ2' : '2B3856Y9QW'); //T5X6BFGPZ2 has been deleted but still might be attached to optimize dev container
        //
        // if (!obj.isDev){
        //     obj.ga4_stream.push('WE3BPRQKW0'); //prod data to test property
        // }
    }
}

if (obj.runGA || obj.isUtility || obj.isMicroSite){
    obj.ga4_stream.push(obj.isDev ? '5TN38N31ZV' : 'Q5CTTQBJD8');
}

if (obj.isElectricFleet){
    obj.ga4_stream.push('VZYNGW1LXW'); //shelton group wants a standalone ga4 property. see email thread: [EXTERNAL] Fleet Web Performance KO
}

if (obj.isNZA && obj.isProd){
    obj.ga4_stream.push('N9PNGR23NQ'); //constructive consultant wants a standalone ga4 property. see email thread: [EXTERNAL] Re: NZAA Analytics
}

if (obj.isCVE && obj.isProd){
    obj.ga4_stream.push('Z7CXW7H60F');
}

let stream;

if (obj.isMRD){
    stream = 'LBSKXQ0948';
    obj.ga4_linker_domains = [(obj.isDev ? 'edf.org' : 'mississippiriverdelta.org'), 'onlineaction.org'];
}
else if (obj.isMM){
    stream = 'P94G5ZB0T3';
}
else if (obj.isCBJ){
    stream = '9DZF9FHXC7';
}
else if (obj.isSSFH){
    stream = 'CLDT3418RG';
}
else if (obj.isMethaneSat && !obj.isDev){
    stream = 'SXQYQWNJ8D';
}
else if (obj.isTFCI){
    stream = 'Y9K5R97GF4';
}
else if (obj.isGCA){
    stream = 'JFHPM2052Z';
}
else if (obj.isEU || obj.isCMEU){
    stream = '38V2B5X1BE';
}
else if (obj.isCMCA && obj.isProd){
    stream = 'EZQ2B89XX7';
}
else if (obj.isCC){
    stream = 'H014WDR7KR';
}
else if (obj.isInsider && obj.isProd){
    stream = 'DKN2KR75RQ';
}
else if (obj.isDOF || obj.isDOFC4){
    stream = 'Y5CFQ6QMCL';  //https://analytics.google.com/analytics/web/?hl=en#/a53911923p322347966/admin/streams/table/3826112874
}
else if (obj.isMCAF || obj.isMCAFC4){
    stream = 'Y8LNLM894J';
}
else if (obj.isFRI){
    if (new Date() < new Date('2022-07-18')){
        obj.isDev = true;
    }

    stream = obj.isDev ? 'H2CYB4HQWD' : '97NS13GC4X';
    obj.ga4_linker_domains = [obj.isDev ? 'edf.org' : 'frontlineresourceinstitute.org'];
}
else if (obj.HN.indexOf('americasgulf.org') > -1){ //utilities load via gtm
    stream = 'KX13351HSZ';
}
else if (obj.isCVI && !obj.isDev){ //do not run for demo.edf.dha.io
    stream = 'Z5FE2Y6K0B';
}
else if (obj.isAAAClimate){
    stream = 'LT20KCPKH8';
}
else if (obj.isCBNS){
    stream = 'CT19V94LZY';
}

if (stream){
    obj.ga4_stream.push(stream);
}

obj = Object.assign(obj, constants);

for (let prop in obj){
    config[prop] = obj[prop];
}

for (let prop in obj.GAOBJ) {
    config.us.push(prop);
}

if (config.isTugboat){
    config.us.push(config.HN);
}

if (!config.isClassy){
    config.exclude_params.push('cid');
}
else {
    let classy_props = {
        brand: config.classy_brand,
        campaign_id: null,
        campaign_name: null,
        internal_name: null,
        organization_id: null,
        organization_name: null
    };

    if (window.SC && window.SC.campaign){
        classy_props.campaign_id = SC.campaign.id;
        classy_props.campaign_name = SC.campaign.name;
        classy_props.internal_name = SC.campaign.internal_name;
        classy_props.organization_id = SC.organization.id;
        classy_props.organization_name = SC.organization.name;
    }
    else if (config.classy_studio && config.classy_studio.props && config.classy_studio.props.pageProps){
        let page_props = config.classy_studio.props.pageProps;

        classy_props.campaign_id = parseInt(page_props.cid);
        classy_props.organization_id = parseInt(page_props.oid);
        classy_props.organization_name = config.classy_org;

        classy_props.campaign_name = page_props.title;

        if (!classy_props.campaign_name && page_props.pageConfig.campaignName){
            classy_props.campaign_name = page_props.pageConfig.campaignName;
        }

        if (!classy_props.organization_id && page_props.pageConfig.orgId){
            classy_props.organization_id = page_props.pageConfig.orgId; //already an integer
        }

        if (!classy_props.campaign_id && page_props.metadata){
            classy_props.campaign_id = parseInt(page_props.metadata.campaignId);
        }
    }

    //https://donate.sandbox.edf.org/checkout?cid=600793&oid=71388&amount=35.00&frequency=one-time&currency=USD&step=1
    if (config.PN === '/checkout'){
        classy_props.frequency = gup('frequency');
        classy_props.amount = parseFloat(gup('amount').replace('$',''));
    }

    config.classy_props = classy_props;
    window.classy_props = classy_props;
}

let params = getUrlParams();
for (let param in params){
    config.pii_param_strings.forEach(function(pattern){
        if (param.toLowerCase().replace(/[^a-z]/g,'').indexOf(pattern) > -1){
            config.exclude_params.push(param);
        }
    });

    let value = (params[param]+'').trim();

    if (isEmail(value) || config.ssn_regex.test(value)
        || (config.phone_number_regex.test(value) && ['utm_id', 'ut_id', 'conversion_pg_qry'].indexOf(param) === -1)
    ){
        config.pii_param_strings.push(param);
        config.exclude_params.push(param);
    }
}

let namespace = (config.isClassy || config.isGP ? 'edf_' : '')+'dataLayer';

export {
    config,
    namespace
}