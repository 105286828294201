import { config } from "./config";
import { SS_jsonWrapper } from "./ss_json_wrapper";

export function activeP2ACampaigns(tag){

    function getBrand(n) {
        n = n || 0;

        if (_brands[n][0] !== undefined) {
            if (window[_brands[n][0]] === _brands[n][1] && _brands[n].length === 3) {
                return _brands[n][2];
            }
            else if (window[_brands[n][0]] && typeof window[_brands[n][0]] === 'boolean') {
                return _brands[n][1];
            }
            else {
                return getBrand(n + 1);
            }
        }
    }

    function getDescription(str, ct, link){
        let new_str = str.split(' ');

        if(!ct || (ct && new_str.length <= ct)){
            return str;
        }

        new_str = new_str.splice(0, ct).join(' ');
        return new_str + (new_str.split('')[new_str.split('').length-1].match(/^[!.?]/) ? '' : '...') + ` <a href="${link}">Read more</a>`;
    }

    let _brands = [
        ['isMRD', 'mrd'],
        ['isMCAFC4', 'cama'],
        ['isMCAF', 'mcaf'],
        ['isDOFC4', 'dofaction'],
        ['isDOF', 'dof'],
        ['isEDAF', 'edfaction'],
        ['site_mode', 'C4', 'edfaction'],
        ['isEDF', 'edf']
    ];

    let brand = tag.dataset.brand || getBrand(0);

    SS_jsonWrapper('p2aCampaigns'+brand, config.utility_path+'p2a/'+brand+'/campaigns', function(json){
        let list = '',
            no_campaigns = '<p><strong>There are currently no active campaigns.</strong></p>',
            clss = 'p2a-campaign__item',
            word_count = tag.dataset.word_count || false,
            show_pub_date = !tag.dataset.publish_date || tag.dataset.publish_date==='true';

        if (json && Array.isArray(json) && json.length){
            json.sort(function(a,b){
                if (a.created_at > b.created_at){
                    return -1;
                }

                if (a.created_at < b.created_at){
                    return 1;
                }

                return 0;
            });

            for (let n = 0; n < json.length; n++) {
                let img = json[n].share_image || json[n].content_background,
                    image = '',
                    cta = tag.dataset.cta ? `<p class="${clss}-cta"><a href="${json[n].link}">${tag.dataset.cta}</a></p>` : '';

                if (tag.dataset.images === 'true' && img){
                    image = `
				            <div class="${clss}-image">
				                <img class="imgFlex" src="${img}" style="width:100%;" />
				            </div>
				        `;
                }

                if ((json[n].name+'').toLowerCase().replace(/ /g, '').indexOf('[regional]') === -1 || tag.dataset.regional === 'true'){
                    list += `
                        <li class="${clss} ${clss}-${json[n].id}">
                            ${image}
                            <div>
                                <h4 class="${clss}-title">
                                    <a href="${json[n].link}">${trim((json[n].subtitle || json[n].name.split(' [')[0] + '').replace(brand.toUpperCase() + ':', ''))}</a>
                                </h4>
                                <div>
                                    ${show_pub_date ? `<p class="credit"><span class="date ${clss}-date">${json[n].created_at.split('T')[0]}</span> </p>` : ''}
                                    <p class="${clss}-description">${getDescription(json[n].content_introduction || json[n].subject, word_count, json[n].link)}</p>
                                    ${cta}
                                </div>
                            </div>
                        </li>
                    `;
                }

                list = list || no_campaigns;
            }
        }
        else {
            list = no_campaigns;
        }

        tag.innerHTML = list;
    });
}