import {config} from "./config";
import {$qs, appendHTML} from "./dom";
import {$id, gti} from "./helpers";
import {addEvent, removeEvent} from "./events";
import {wuphf} from "./storage";
import {dataLayer} from "./datalayer";
import * as classy from "./classy";

let running = false;

export function runDataMonitor(){
    let handler = function(e){
        let detail = e.detail;
        if (detail && typeof detail === 'object'){
            if (!detail.arg1 && !detail.arg2){
                console.log(JSON.stringify(detail, null, '\t'));
            }
        }
    };

    if (!running){
        addEvent('data_monitor', handler);
        running = true;
    }

    setTimeout(function(){
        removeEvent('data_monitor', handler);
        running = false;
    }, 60000);
}

export function clearChumsTesting(reload){
    let isTesting = 'isTesting';
    wuphf('chums', null, -1);
    window[isTesting] = false;
    dataLayer[0][isTesting] = false;
    config[isTesting] = false;

    if (reload){
        window.location.reload();
    }
}

export function testOutboundLinks(){
    let id = 'link_'+gti();

    appendHTML({
        a: document.body,
        elem: 'a',
        href: 'https://www.facebook.com',
        id: id
    });

    runDataMonitor();

    $id(id).click();
}

export function testIESignup(){
    appendHTML({
        a: $qs('main'),
        elem: 'div',
        innerHTML: `<div class="ConvioSignup" data-addl="testing-innovation-and-environment" data-lists="50325"> 
                        <h3>Signup for the Innovation and the Environment newsletter</h3> 
                    </div>`,
        style: 'border: 1px solid red; padding: 20px;'
    });

    ConvioSignup.initialized(ConvioSignup.attach);
}

export function GA4EventThrottling(){
    runDataMonitor();

    for (let i=0; i<3; i++){
        GAEvent('test_event', {
            ec: 'test_category',
            el: 'test_label'
        });
    }
}

export function testMultiListSignup(){
    appendHTML({
        a: $qs('main'),
        elem: 'div',
        innerHTML: `<div class="c-block__inner"><div class="ConvioSignup" data-lists_labels="This,That:checked,And the other" data-lists="1,2,3" data-lists_disclaimer="true"> 
                        <h3>Signup for these lists</h3> 
                    </div></div>`,
        className: 'c-block--sign-up'
    });

    ConvioSignup.initialized(ConvioSignup.attach);
}

export function testClassyEmbed(id){ //492291
    let headline = $qs('h1');
    if (headline){
        headline.classList.add('classy-embed');

        if (id){
            headline.dataset.id = id;
        }

        classy.attach();
    }
}