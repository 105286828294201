let tracklytics_prefix = 'tracklytics_',
    pmark_existed = false;

if (window.performance && performance.mark){
    if (performance.getEntriesByName(tracklytics_prefix+'start')[0]){
        pmark_existed = true;
    }
    else {
        performance.mark(tracklytics_prefix+'start');
    }
}

window.is_classy_studio = location.href.indexOf('https://donate.') === 0
    && ((window.classy_studio_props && typeof window.classy_studio_props === 'object')
        || location.pathname.indexOf('/campaign') === 0 || location.pathname.indexOf('/checkout') === 0)

import { NR, generateError, NR_init } from "./new_relic";
if (!window.is_classy_studio){
    NR_init();
}

//hack to prevent utilities from running twice, a result of `defer` being added to primary script tag, allowing the document.write to kick in
if (document.currentScript){
    let prev_script_tag = document.currentScript.previousElementSibling;
    if (prev_script_tag){
        if ((prev_script_tag.innerHTML+'').indexOf('window.$EDF || document.write') > -1){
            console.log('utility fallback mode');
        }
    }
}

let UTILITY_ALREADY_RAN = !!window.UTILITY_IS_LOADING;
window.UTILITY_ALREADY_RAN = UTILITY_ALREADY_RAN;
window.UTILITY_IS_LOADING = true;

if (UTILITY_ALREADY_RAN){
    console.log('UTILITY_ALREADY_RAN', UTILITY_ALREADY_RAN);
    throw new Error("Stop script");
}

import { config } from "./config";
import { dataLayer, resurrected_dl, checkRemoteStorage } from "./datalayer";
import * as _ from "./helpers";

//NOTE: including empty references to prevent downstream breaks
import {Sentry, sentryMessage, setSentryTag, setSentryTags, errorCount, unhandledrejectionCount, SentryEvents, SentryExceptions} from "./sentry";

import { edf_init, custom_transfers, decorateLink, clearDL, getNetworkReqs, resources, resourceTypes, listFonts } from "./init";
import * as $ from "./dom";
import * as storage from "./storage";
import { addReadyEvent, addLoadEvent, addLoadEventWithTimeout, addEvent, jQueryOnLoad, triggerEvent, listenForEvent, stopListeningForEvent, removeEvent, processEvent, addUnLoadEvent, fireCustomEvent } from './events';
import * as userFlags from "./user_flags";
import {getUserIds} from "./user";
import * as forms from "./forms";
import * as ut from "./ut";
import * as adestra from './adestra_auth';
import * as Base64 from './base64';
import * as detection from './client_detection';
import { activeP2ACampaigns } from "./p2a_feeds";
import { mobilizeEvents } from "./mobilize_feeds";
import {_LA, jsExt, loadScript, pixeler, loadStyle, loadAssets, loadFancyBox, injectCSS} from "./load_assets";
import {onCMEUAcceptsCookies, site_hooks, triggerYouTubeAttach} from './site_hooks';
import {clicker} from "./click_handler";
import {GAEvent, userTiming, virtualPage } from "./ga";
import {ajaxReq} from "./ajax_req";
import {SS_jsonWrapper} from "./ss_json_wrapper";
import {track_engagement} from "./engagement";
import * as valid from "./validators";
import * as func from "./functions";
import {ajaxSniffer} from "./ajax_sniffer";
import {on_signup, on_mailchimp_signup} from "./conversions";
import {testBucket, experiment, experiments_on_page} from "./testing";
import {lookup as ip_lookup} from "./ip";
import {youtube_load, youtube_player, youtube_close, attachYoutube, getVideos, yt_loaded} from "./youtube";
import {manageCookieOptin} from "./cookie_opt_in";
import * as cookie_consent from "./cookie_consent";

if (_.gup('from') === 'edf_cc'){
    window.block_default_overlay_displays = true;
}

if (config.isCMEU){
    manageCookieOptin(onCMEUAcceptsCookies);
}

window.blockLinkClicks = false;

//these are run in classy index.js
edf_init();
site_hooks();
clicker();
//these are run in classy index.js

track_engagement();

window.ConvioSignup = {
    validateSignup: true,
    initialized: function(callback) {
        callback = callback || _._ef;

        if (typeof window.ConvioSignup.submit === 'function') {
            window.ConvioSignup.attach();
            return callback();
        }

        jQueryOnLoad(function(){
            _LA('signups', function(){
                setTimeout(function(){
                    window.ConvioSignup.attach();
                    callback();
                }, 5);
            });
        });
    }
};

[_, $, forms, detection, storage, valid, func].forEach(function(mod){
    for (let prop in mod){
        window[prop] = mod[prop];
    }
});

window.GAOBJ = config.GAOBJ;

config.props_for_global.forEach(function(prop){
    window[prop] = typeof config[prop] !== 'undefined' ? config[prop] : null;
});

for (let prop in config){
    if (prop.indexOf('is') === 0 && prop.length > 2 && typeof config[prop] === 'boolean'){
        window[prop] = config[prop];

        if (config[prop] === true){
            ut.setPage(prop, config[prop]);
        }
    }
}

window.ut = ut;
window.testBucket = testBucket;

window.adestra = {};
for (let prop in adestra){
    window.adestra[prop] = adestra[prop];
}

window.tim = _.tim;
window.setFields = forms.setFields;

window.getUser = function(){
    getUser();
}
window.setUser = {
    done: function(cb){
        cb();
    }
}

window.addReadyEvent = addReadyEvent;
window.addLoadEvent = addLoadEvent;
window.addLoadEventWithTimeout = addLoadEventWithTimeout;
window.addUnLoadEvent = addUnLoadEvent;
window.addEvent = addEvent;
window.processEvent = processEvent;
window.jQueryOnLoad = jQueryOnLoad;
window.triggerEvent = triggerEvent;
window.listenForEvent = listenForEvent;
window.stopListeningForEvent = stopListeningForEvent;
window.removeEvent = removeEvent;
window.fireCustomEvent = fireCustomEvent;

window.GAEvent = GAEvent;
window.userTiming = userTiming;
window.virtualPage = virtualPage;
window.TE = GAEvent;

window.activeP2ACampaigns = activeP2ACampaigns;
window.mobilizeEvents = mobilizeEvents;

window.ajaxReq = ajaxReq;
window.pixeler = pixeler;
window.injectCSS = injectCSS;
window.loadAssets = loadAssets;
window.loadScript = loadScript;
window.loadStyle = loadStyle;
window.jsExt = jsExt;
window.SS_jsonWrapper = SS_jsonWrapper;
window.loadFancyBox = loadFancyBox;
window.custom_transfers = {
    process: custom_transfers
};

//TODO pair these down (maybe with underscore/pattern filtering). Not everything should or needs be exposed. Plus, it just prevents further minification
export * from './config';
export * from './helpers';
export * from './load_assets';
export * from './events';
export * from './dom';
export * from './client_detection';
export * from './storage';
export * from './functions';
export * from './validators';
export * from './ga';
export * from './forms';
export * from './tests';
export * from './pixels';
//export * as classy from './classy';
export {everyaction_form_meta, appendEATag} from './everyaction';
export * as lytics from './lytics';
export {track} from './track';
export {observeElement} from './observe_element';
export * as HTML5AV from "./html5_audio_video";
export {onSubmit} from "./on_submit"

export {
    resurrected_dl,
    checkRemoteStorage,
    dataLayer,
    clearDL,
    getUserIds,
    adestra,
    ut,
    userFlags,
    Base64,
    mobilizeEvents,
    activeP2ACampaigns,
    custom_transfers,
    decorateLink,
    on_signup,
    on_mailchimp_signup,
    SS_jsonWrapper,
    ip_lookup,
    ajaxReq,
    getVideos,
    yt_loaded,
    youtube_load,
    youtube_player,
    youtube_close,
    attachYoutube,
    getNetworkReqs,
    listFonts,
    resources,
    resourceTypes,
    testBucket,
    experiments_on_page,
    experiment,
    ajaxSniffer,
    manageCookieOptin,
    Sentry,
    sentryMessage,
    setSentryTag,
    setSentryTags,
    errorCount,
    unhandledrejectionCount,
    SentryEvents,
    SentryExceptions,
    triggerYouTubeAttach,
    NR,
    generateError
}

if (window.performance && performance.mark){
    performance.mark(tracklytics_prefix+'end');
    performance.measure(tracklytics_prefix+'span', tracklytics_prefix+'start', tracklytics_prefix+'end');

    if (config.log){
        let measure = window.performance.getEntriesByName(tracklytics_prefix+'span');
        if (measure[0]){
            console.log(tracklytics_prefix+'span: '+measure[0].duration);
        }
    }
}