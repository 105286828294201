import {config} from "./config";
import {wait, $id} from "./helpers";
import { addReadyEvent } from './events';
import {readCookie, blockCookies, createCookie, allowCookies} from "./storage";
import {appendHTML, forAll} from "./dom";
import {injectCSS} from "./load_assets";

//TODO migrate to new cookie consent method

export function manageCookieOptin(callback){
    callback = callback || function(){};

    let cookie_agreed = 'cookie-agreed',
        cookie_value = readCookie(cookie_agreed),
        cookie_btn = 'cpb-btn';

    function track(action, label){
        GAEvent('cookie_opt_in', action, label || 'cpb');
    }

    if (['0','1'].indexOf(cookie_value) === -1){
        blockCookies(cookie_agreed);

        injectCSS(`
            .cpb {position: fixed; z-index: 99999; bottom: 0; left: 0; width: 100%; background: #203f85; font-family: Arial}
            .cpb-body {padding: 20px; color: white}
            .cpb-body p {padding: 0; line-height: 20px; font-size: 16px}
            .${cookie_btn} {cursor: pointer; border: 3px solid white; font-weight: bold; background: #203f85; color: white; font-size: 18px; padding: 5px; margin-right: 10px}
        `);

        addReadyEvent(function(){
            appendHTML({
                a: 'body',
                elem: 'div',
                innerHTML: `
                    <div id="cpb" class="cpb">
                        <div class="cpb-body">
                            <p>We use cookies on this site to enhance your user experience</p>
                            <p>By clicking "OK, I Agree" you are giving your consent for us to set tracking cookies.</p>
                            
                            <button type="button" id="${cookie_btn}-ok" class="${cookie_btn} ${cookie_btn}-ok">OK, I agree</button>
                            <button type="button" id="${cookie_btn}-no" class="${cookie_btn} ${cookie_btn}-no">No thanks</button>
                            <button type="button" id="${cookie_btn}-info" class="${cookie_btn} ${cookie_btn}-info">More info</button>
                        </div>
                    </div>
                `,
            });

            track('display');

            let banner = $id('cpb');

            forAll('.'+cookie_btn, function(elem){
                addEvent(elem, 'click', function(e){
                    let id = e.target.id;

                    if (id === cookie_btn+'-no'){
                        createCookie(cookie_agreed, 0, 200);

                        track('click', 'no');
                        banner.remove();
                    }
                    else if (id === cookie_btn+'-ok'){
                        allowCookies();
                        createCookie(cookie_agreed, 1, 200);

                        track('click', 'ok');
                        banner.remove();

                        callback();
                    }
                    else if (id === cookie_btn+'-info'){
                        if (config.isEU || config.isCMEU || config.isDev){
                            track('click', 'info');
                            wait('https://www.edfeurope.org/cookie-notice', 50);
                        }
                    }
                });
            });
        });
    }
    else if (cookie_value === '0'){
        blockCookies(cookie_agreed);
    }
}